<template>
<div class="tableList">
  <div class="table" >
    <el-table :data="tableData" stripe style="width: 100%" border>
    <el-table-column prop="evaluationBidCode" label="评标编号" min-width="300"> </el-table-column>
    <el-table-column prop="itemName" label="项目名称" min-width="300"> </el-table-column>
    <el-table-column prop="businessScope" label="经营范围" >
      <template #default="scope">
        {{setBusinessScope(scope.row.businessScope)}}
      </template>
    </el-table-column>
    <el-table-column prop="bidStartDate" label="开标日期" min-width="230"> </el-table-column>
    <el-table-column prop="createUserName" label="采购员" min-width="200"> </el-table-column>
    <el-table-column prop="status" label="状态" min-width="200">
      <template #default="scope">
        {{scope.row.status=="0"?"待评标":"已完成"}}
      </template> </el-table-column>
    <el-table-column label="操作" min-width="200" fixed="right">
      <template #default="scope">
        <el-button  size="mini"  type="text"  @click="handleShow(scope.$index, scope.row)">查看</el-button>
      </template>
    </el-table-column>
    </el-table>
  <div class="tablepage" v-if="tableData&&tableData.length>0">
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size = pageSize
     layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
  </div>
  </div>
</div>
</template>

<script>
import { request } from '@/assets/js/http.js'
export default {
  name: 'ExpertMarkTable',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableData: [],
      pageNum: 1,
      pageSize: pageSize,
      searchData: {},
      name: '',
      total: 100
    }
  },
  created () {
    this.getScoreList()
  },
  methods: {
    setBusinessScope: (code) => {
      if (code === 'KK') {
        return '可控'
      }
      if (code === 'SG') {
        return '曙光'
      }
      if (code === 'NC') {
        return '宁畅'
      }
      if (code === 'TL') {
        return '腾龙'
      }
    },
    handleShow: function (index, data) {
      this.$emit('buttonClick', { alias: 'evaluationBidForm', openView: 'openView', query: { id: data.bidId, eid: data.expertMessageId, status: data.status } })
    },
    // 获取分页列表
    getScoreList () {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      var getdata = { ...obj, ...this.searchData }
      request('/api/bid/getScoreList', 'get', getdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },
    search (data) {
      this.searchData = data
      this.getScoreList()
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.getScoreList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.getScoreList()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
</style>
