<template>
  <div class="tableNav" :class="{close:isclose,havepad:isPaddnig}" :style="{height:tableNavHeight}">
    <div class="searchList" :class="{havepad:isPaddnig}">
      <div class="col col2" :key="key" v-for="(item,key) in searchList">
        <div class="title">
          {{item.labe}}
        </div>
        <div class="input">
          <el-input @keyup.enter="search" v-model="searchData[item.code]" :placeholder="item.placeholder || '请输入'" v-if="item.type=='input'"></el-input>
          <el-autocomplete class="inline-input" v-model="searchData[item.code]" :fetch-suggestions="item.fetchSuggestions"
          :trigger-on-focus="item.triggerOnFocus" :placeholder="item.placeholder || '请输入'" v-if="item.type=='inputAutoComplete'"></el-autocomplete>
          <el-select filterable v-model="searchData[item.code]" v-if="item.type=='select'" :placeholder="item.placeholder || '请选择'">
              <el-option :key="key1" :value="item1.value" v-for="(item1,key1) in item.option"
                  :label="item1.label">
              </el-option>
          </el-select>
          <el-date-picker v-if="item.type=='daterange'"
                v-model="searchData[item.code]"
                type="daterange"
                range-separator="至"
                :start-placeholder="item.startPlaceholder || '请选择'"
                :end-placeholder="item.endPlaceholder || '请选择'">
            </el-date-picker>
            <el-date-picker
              v-if="item.type=='date'"
              v-model="searchData[item.code]"
              type="date"
              :placeholder="item.placeholder || '请选择'">
            </el-date-picker>
        </div>
      </div>
    </div>
    <div class="searchButton">
        <el-button @click="search" type="primary" size="small" class="search">查询</el-button>
        <el-button @click="clearData" plain size="small" class="clearData">清空</el-button>
        <el-button @click="open" size="small" type="text" class="openClose">{{isclose?"展开":"收起"}}<i :class="[{'el-icon-arrow-down':isclose},{'el-icon-arrow-up':!isclose}]"></i></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListSearch',
  props: ['childData'],
  data () {
    return {
      searchList: this.childData.searchList,
      searchData: this.childData.searchData,
      isclose: true,
      tableNavHeight: '54px',
      isPaddnig: false
    }
  },
  created: function () {
    this.$nextTick(function () {

    })
  },
  watch: {
    childData: function (newVal) {
      this.searchList = newVal.searchList
      this.searchData = newVal.searchData
    }
  },
  methods: {
    open () {
      this.isclose = !this.isclose
      this.isPaddnig = false
      if (!this.isclose) {
        this.$nextTick(function () {
          var lastDom = document.querySelector('.searchList .col:last-child')
          var butDom = document.querySelector('.searchButton')
          if (lastDom.offsetLeft + lastDom.offsetWidth > butDom.offsetLeft && lastDom.offsetTop + lastDom.offsetHeight >= butDom.offsetTop) {
            this.isPaddnig = true
          }
          this.tableNavHeight = document.querySelector('.searchList').scrollHeight + 'px'
        })
      } else {
        this.tableNavHeight = '54px'
      }
    },
    search () {
      this.$emit('onSearch', this.searchData)
    },
    clearData () {
      Object.keys(this.searchData).forEach((key) => {
        this.searchData[key] = ''
      })
      this.$emit('clearSearch', this.searchData)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/listSearch.scss';
</style>
