<template>
  <div class="expertmarkList">
    <list-search :childData="childData"  @onSearch="search" @clearSearch="search"></list-search>
    <expert-mark-table ref="showTable" @buttonClick="buttonClick"></expert-mark-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import { ListSearchList, ListSearchData } from '@/views/invitation/expermark/js/bidevaluation.js'
import ExpertMarkTable from './ExpertMarkTable.vue'
import dayjs from 'dayjs'
import { request } from '@/assets/js/http.js'

export default {
  components: { ListSearch, ExpertMarkTable },
  name: 'ExpertMark',
  data () {
    return {
      childData: { searchList: ListSearchList, searchData: ListSearchData },
      searchdata: {}
    }
  },
  created: function () {
    request('/api/sap/businessScope/getBusinessScopeList', 'get').then((response) => {
      this.childData.searchList[2].option = [{ label: '全部', value: '' }]
      for (var item of response) {
        var tempdata = {}
        tempdata.label = item.companyDesc
        tempdata.value = item.businessScope
        this.childData.searchList[2].option.push(tempdata)
      }
    })
  },
  methods: {
    search: function (data) {
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.endTime = this.dateFormate(data.createDate[1])
        this.searchdata.startTime = this.dateFormate(data.createDate[0])
      }
      this.$refs.showTable.search(this.searchdata)
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    buttonClick: function (data) {
      this.$router.push({ params: { viewName: data.alias }, query: data.query })
      this.$emit('OpenView', { openView: data.alias })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
