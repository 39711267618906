export var ListSearchList = [
  { labe: '评标编号', code: 'code', type: 'input' },
  { labe: '项目名称', code: 'itemName', type: 'input' },
  {
    labe: '经营范围',
    code: 'businessScope',
    type: 'select',
    option: [
      { label: '全部', value: 'All' }
    ]
  },
  {
    labe: '状态',
    code: 'status',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '待评标', value: '0' },
      { label: '已结束', value: '1' }
    ]
  },
  {
    labe: '发布日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var ListSearchData = {
  evaluationBidCode: '',
  itemName: '',
  businessScope: '',
  status: '',
  createDate: '',
  endTime: '',
  startTime: ''
}
